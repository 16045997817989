import { Period } from "../../tenders/services/tenders-search.service";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";

export function isSameDay(date1: Date, date2: Date) {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

export function isSamePeriod(period1: Period | null, period2: Period | null) {
  if (!period1 || !period2) return false;
  return isSameDay(new Date(period1.from), new Date(period2.from)) && isSameDay(new Date(period1.to), new Date(period2.to));
}


export function toDayPeriod(to_date: Date | string, from_date?: Date | string): Period {
  const to = new Date(to_date);
  to.setUTCHours(6, 0, 0, 0)
  let from;
  if (from_date) {
    from = new Date(from_date);
  } else {
    from = new Date(to_date);
    from.setDate(to.getDate() - 1);
  }
  from.setUTCHours(6, 0, 0, 0);
  return {
    from: from.toISOString(),
    to: to.toISOString()
  }
}

export function findPeriod(date: Date, createdAt?: string, marketDates?: string[]) {
  if (marketDates) {
    const currentDate = marketDates.find(d => isSameDay(new Date(d), date))
    if (currentDate) {
      // If the date is among the market watch dates, then return the corresponding market watch period.
      const indexOfCurrentDate = marketDates.indexOf(currentDate)
      if (indexOfCurrentDate === 0) {
        // The period corresponding to the first watch reception is between this date and the watch creation.
        return toDayPeriod(currentDate, createdAt)
      } else return toDayPeriod(currentDate, marketDates[indexOfCurrentDate - 1])
    } else if (date < new Date(marketDates[0])) {
      // If the date is before the first market watch date, then return the first market watch period instead.
      return toDayPeriod(marketDates[0], createdAt)
    } else if (date > new Date(marketDates[marketDates.length - 1])) {
      // If the date is after the last market watch date, then return the last market watch period instead.
      return toDayPeriod(marketDates[marketDates.length - 1], marketDates[marketDates.length - 2])
    } else return toDayPeriod(date);
  } // No marketDates provided, just return the basic 1-day period.
  else return toDayPeriod(date);
}


export function ngbDateToDate(date: NgbDate) {
  return new Date(`${date.year}-${date.month}-${date.day}`)
}

export function stringToNgbDate(date: string) {
  // String has to be of the form 'YYYY-MM-DD'
  const segments = date.split('-')
  return new NgbDate(+segments[0], +segments[1], +segments[2]);
}
